import { device } from 'src/styles/breakpoints'
import { orange, primary } from 'src/styles/newColors'
import styled from 'styled-components'

export const Section = styled.section`
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/cdpj-hero-mobile/image.webp');
  background-repeat: no-repeat;
  background-size: cover;
  height: 450px;

  @media ${device.tablet} {
    background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/cdpj-hero-web/image.webp');
    height: 365px;
  }

  @media ${device.desktopLG} {
    height: 490px;
  }

  @media ${device.desktopXL} {
    height: 680px;
  }
  
`

export const Title = styled.h1`
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  font-family: 'Citrina', Helvetica, sans-serif;
  color: ${primary[500]};

  @media ${device.tablet} {
    font-size: 28px;
    line-height: 34px;
  }

  @media ${device.desktopLG} {
    font-size: 40px;
    line-height: 44px;
  }

  @media ${device.desktopXL} {
    font-size: 56px;
    line-height: 62px;
  }
`

export const Text = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: ${orange.dark};
  font-family: 'Citrina', Helvetica, sans-serif;

  @media ${device.tablet} {
    font-size: 20px;
    line-height: 24px;
  }

  @media ${device.desktopLG} {
    font-size: 28px;
    line-height: 34px;
  }
`
