import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import { orange } from 'src/styles/newColors'
import styled from 'styled-components'

export const Section = styled.section`
  background-color: ${grayscale[100]};
  @media ${device.desktopXL} {
    img {
      width: 80%;
    }
  }
`
export const Title = styled.h2`
  color: ${orange.dark};
  font-size: 24px;
  line-height: 28px;
  font-family: 'Citrina', Helvetica, sans-serif;

  @media ${device.tablet} {
    font-size: 28px;
    line-height: 32px;
  }

  @media ${device.desktopXL} {
    font-size: 48px;
    line-height: 52px;
  }
`
export const Text = styled.p`
  display: inline;
  color: ${orange.dark};
  font-size: 14px;
  line-height: 18px;
`
