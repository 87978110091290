import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import { orange, sand } from 'src/styles/newColors'
import styled from 'styled-components'

export const Section = styled.section`
  background-color: ${sand};
`
export const Title = styled.h2`
  color: ${orange.dark};
  font-weight: 500;
  font-family: 'Citrina', Helvetica, sans-serif;
  font-size: 28px;
  line-height: 32px;

  @media ${device.desktopXL} {
    font-size: 48px;
    line-height: 54px;
  }
`
export const Texts = styled.p`
  color: ${orange.dark};
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 0;
  display: inline;

  @media ${device.desktopLG} {
    font-size: 18px;
    line-height: 22px;
  }
`
export const Table = styled.table`
  width: 100%;
  text-align: center;
  margin-top: 30px;

  th {
    background-color: ${orange.dark};
    padding: 24px 0;
    color: ${sand};
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    font-family: 'Citrina', Helvetica, sans-serif;
    font-size: 24px;
    line-height: 28px;
  }

  td {
    padding: 16px;
    color: ${orange.dark};
  }

  tr:nth-child(2) {
    td {
      font-family: 'Citrina', Helvetica, sans-serif;
      font-size: 24px;
      line-height: 28px;
    }
  }

  tr:nth-child(even) {
    background-color: ${grayscale[100]};
  }

  tr:nth-child(odd) {
    background-color: white;
  }

  tr:last-child {
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
  }
`
