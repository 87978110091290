import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import { graphite, orange } from 'src/styles/newColors'
import styled from 'styled-components'

export const Section = styled.section`
  background-color: ${grayscale[100]};
`

export const Title = styled.h2`
  color: ${orange.extra};
  font-family: 'Citrina', Helvetica, sans-serif;
  font-size: 24px;
  line-height: 28px;

  @media ${device.desktopLG} {
    font-size: 28px;
    line-height: 32px;
  }

  @media ${device.desktopLG} {
    font-size: 48px;
    line-height: 52px;
  }

`

export const Text = styled.p`
  color: ${graphite};
  font-size: 16px;
  line-height: 20px;
  display: inline;
  margin-bottom: 0;

  @media ${device.desktopLG} {
    font-size: 18px;
    line-height: 22px;
  }
`
