import { device } from 'src/styles/breakpoints'
import { orange, sand } from 'src/styles/newColors'
import styled from 'styled-components'

export const Section = styled.section`
  background-color: ${sand};
  position: relative;

  img {
    margin-left: -50px;

    @media ${device.tablet} {
      position: absolute;
      bottom: -40px;
      left: 0;
      height: 275px;
    }

    @media ${device.desktopLG} {
      bottom: -80px;
      height: 300px;
    }

    @media ${device.desktopXL} {
      bottom: -96px;
      height: 350px;
      left: -30%;
    }
    @media ${device.desktopXXXL} {
      left: -35%;
      height: 440px;
    }
  }
`
export const Title = styled.h2`
  font-family: 'Citrina', Helvetica, sans-serif;
  font-size: 24px;
  line-height: 28px;
  color: ${orange.dark};
  font-weight: 500;
  margin-top: 15px;

  @media ${device.desktopXL} {
    font-size: 48px;
    line-height: 52px;
  }
`
export const Text = styled.p`
  color: ${orange.dark};
  font-size: 16px;
  line-height: 20px;
  display: inline;
  margin-left: 10px;
`
